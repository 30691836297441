export const SERVER_HOST = `https://${process.env.GATSBY_SERVER_HOST}`;

// resource hosts
export const RES_HOST = 'https://res.onlive.vn';
export const WWW_HOST = 'https://www.onlive.vn';
export const STATIC_HOST = 'https://static.onlive.vn';

// service hosts
export const LIVE_HOST = 'https://live.onlive.vn';
export const BJ_HOST = 'https://streamer.onlive.vn';

// login hosts
export const LOGIN_HOST = 'https://login.onlive.vn';
export const MEMBER_HOST = 'https://member.onlive.vn';
