import Warning from '~/errors/Warning';

interface ErrorResponseData {
    RESULT: number;
    MESSAGE: string;
}

class HTTPError extends Warning {
    status: number;
    data: ErrorResponseData;

    constructor(status: number, data: ErrorResponseData) {
        super(data.MESSAGE);
        this.status = status;
        this.data = data;
        this.productionMessage =
            'Một lỗi đã xảy ra trong quá trình giao tiếp. Vui lòng thử lại sau.';

        this.name = 'HTTPError';
    }
}

export default HTTPError;
